import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

const Section = styled.section`
  position: relative;
  padding-bottom: 20px;
  max-width: 400px;
  margin: 0 auto;
  .subtitle {
    width: 145px;
    margin: -22px auto 20px;
    background: #fff;
    border-radius: 5px;
  }
  ul.shares {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

    li.share {
      width: 45%;
      position: relative;
      margin-bottom: 10px;
      img {
        border-radius: 8px;
      }
      .share-name {
        position: absolute;
        font-size: calc(4.7vw);
        font-weight: normal;
        color: #fff;
        bottom: 0;
        width: 100%;
        text-align: center;
        background-color: #ed8c58;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
  }

  h2.thank {
    width: 290px;
    height: 30px;
    position: absolute;
    line-height: 26px;
    display: inline-block;
    text-align: center;
    z-index: 2;
    border-radius: 9px;
    border: 1px solid #942100;
    font-size: 12px;
    color: #942100;
    background-color: #fff;
    margin: 10px auto 0px;
    left: 0;
    right: 0;
  }

  @media screen and (min-width: 400px) {
    ul.shares li.share {
      img {
        border-radius: 10px;
      }
      .share-name{
        font-size: 20px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }
  @media screen and (min-width: 500px) {
    max-width: 767px;

    ul.shares {
      li.share {
        width: 30%;
        .share-name {
          font-size: 3.3vw;
        }
      }
    }
  }
  @media screen and (min-width: 767px) {
    padding-bottom: 40px;
    ul.shares li.share .share-name {
      font-size: 25px;
    }
    .subtitle {
      width: 230px;
    }
    h2.thank {
      width: 350px;
      height: 35px;
      font-size: 16px;
      line-height: 33px;
      margin: 22px auto 0px;
    }
  }

  @media screen and (min-width: 1240px) {
    .subtitle {
      width: 300px;
    }
  }
`
const shares = [{
  name: '吳淑敏',
  imageIndex: 1
},{
  name: '馮媛甄',
  imageIndex: 2
},{
  name: 'Darren',
  imageIndex: 3
},{
  name: '柯柯 柯佳辰',
  imageIndex: 4
},{
  name: 'JR 紀言愷',
  imageIndex: 5
},{
  name: '馬叔叔',
  imageIndex: 6
},{
  name: '許岑葦',
  imageIndex: 7
},{
  name: '鄭欣妍Lala',
  imageIndex: 8
},{
  name: 'B2哥 陳彥銘',
  imageIndex: 9
}]

export default ({customerImages}) => {
  return (
    <Section id="customer-share">
      <div className="subtitle">
        <h1>口碑分享</h1>
      </div>
      <ul className="shares">
        {shares.map((share) => {
          return ( <li className="share" key={share.name}>
              <Img fluid={customerImages[share.imageIndex - 1].fluid} alt={share.name}/>
              <h3 className="share-name">{share.name}</h3>
            </li>)
        })}
      </ul>
      <h2 className="thank">感謝知名藝人、部落客及所有客戶極力推薦</h2>
    </Section>
  )
}
