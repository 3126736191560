import React from "react"
import Img from "gatsby-image"
import styled from 'styled-components'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const products = [
  {
    title: "福義軒聯名限定禮盒",
    list: [
      { name: "龍來雙喜雙層禮盒", price: "660元/份", content: "四入芝麻/四入機能原味蛋捲/十二兩油飯/雞腿一隻/紅蛋兩顆", },
      { name: "龍來雙喜雙層禮盒(蛋奶素)", price: "700元/份", content: "八入機能原味蛋捲/十六兩油飯/紅蛋兩顆", },
    ],
  },
  {
    title: "經典彌月油飯",
    list: [
      { name: "經典彌月油飯禮盒", price: "370元/份", content: "十六兩油飯/雞腿一隻/紅蛋兩顆", },
    ],
  },
  {
    title: "彌月油飯",
    list: [
      { name: "油飯禮盒", price: "260元/份", content: "油飯十六兩", },
      { name: "素油飯禮盒", price: "300元/份", content: "油飯十六兩", },
    ],
  },
  {
    title: "傳統謝籃",
    list: [
      { name: "葷食謝籃", price: "1,188元/份", content: "五斤油飯/雞腿一隻/紅蛋兩顆", },
      { name: "素食謝籃", price: "1,288元/份", content: "五斤油飯", },
    ],
  },
]

const Section = styled.section`
  margin-bottom: 30px;

  .container {
    * {
      font-family: serif;
    }
    .product-image {
      position: relative;
      min-width: 0;
      min-height: 0;

      .gatsby-image-wrapper {
        display: block !important;  /* workaround for slick when it's used with gatsby image */
      }
      .slick-dots {
        bottom: 15px;

        li button::before {
          opacity: 1;
          color:#999;
        }
        li.slick-active button::before {
          opacity: 1;
          color:#ef9544;
        }
      }
      .slogan {
        top: 12%;
        left: 7%;
        font-size: 3.8vw;
        letter-spacing: 4px;
        position: absolute;
        color: #fff;
        writing-mode: vertical-rl;
        text-orientation: upright;
      }
    }
    .product-texts {
      .subtitle h2 {
        margin-top: 10px;
      }

      .products {
        margin: 24px auto 10px;
        width: 90%;

        .product-group {
          margin-bottom: 15px;
          .group-title {
            color: #ef9544;
            font-size: 15px;
            margin-bottom: 3.5px;
          }
          .product {
            color: #595757;
            margin-bottom: 8.5px;
            .name-price {
              font-size: 12px;
              border-bottom: 1px solid #ef9544;
              display: flex;
              justify-content: space-between;
              line-height: 18px;
            }
            .content {
              font-size: 12px;
              line-height: 18px;
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 414px) {
    .container {
      .product-texts {
        .products {
          .product-group {
            .group-title {
              font-size: 17px;
            }
            .product {
              .name-price {
                font-size: 13px;
                line-height: 20px;
              }
              .content {
                font-size: 13px;
                line-height: 20px;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 767px) {
    background: #ededed;
    margin-bottom: 0px;
    .container {
      .product-image .slogan {
        font-size: 14px;
      }
      margin: 0px auto;
      max-width: 800px;
      padding: 30px 0 0 0;
      display: flex;

      .product-image {
        flex: 1;
      }
      .product-texts {
        background: #fff;
        flex: 1;
      }
    }
  }

  @media screen and (min-width: 960px) {
    .container {
      margin: 0px 120px;
      max-width: 1000px;

      .product-image .slogan {
        font-size: calc(0.04 * (50vw - 120px));
      }

      .product-texts .products .product-group {
        .product {
          .name-price {
            font-size: 13px;
            line-height: 18px;
          }
          .content {
            font-size: 13px;
            line-height: 18px;
          }
        }
      }
    }
  }

  @media screen and (min-width: 1080px) {
    .container {
      .product-texts .products .product-group {
        .group-title {
          font-size: 20px;
        }
        .product {
          .name-price {
            font-size: 16px;
            line-height: 24px;
          }
          .content {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }
  }

  @media screen and (min-width: 1240px) {
    .container {
      margin: 0px auto;
      .product-image .slogan {
        font-size: 19px;
      }
    }
  }

  .hint {
    text-align: center;
    font-size: 13px;
    padding: 20px 0;
    @media screen and (min-width: 767px) {
      font-size: 16px;
    }
  }

  .main-ingredients {
    .title {
      border: 1px solid;
      padding: 4px 10px;
      width: fit-content;
      margin: 10px 0px;
      font-size: 20px;
    }

    .item {
      margin-left: -9px;
    }
  }
`
export default ({slideImages}) => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 5000
  };

  return (
    <Section id="price-menu">
      <div className="container">
        <div className="product-image">
          <Slider {...settings}>
            {slideImages.map((image,i) =>  <Img fluid={image.fluid} key={i} alt={`人之初的美味絕對值得等待-${i}`}/>)}
          </Slider>
          <span className="slogan">
            人之初的美味絕對值得等待
            <br />
            每日限量供應只為更好的服務
            <br />
            口耳相傳的古早味油飯，堅持將最好的祝福送給您
          </span>
        </div>
        <div className="product-texts">
          <div className="subtitle">
            <h2>價目表</h2>
          </div>
          <div className="products">
            {products.map(product => {
              return (
                <div className="product-group" key={product.title}>
                  <h3 className="group-title">{product.title}</h3>
                  {product.list.map(item => {
                    return (
                      <div className="product" key={item.name + item.price}>
                        <div className="name-price">
                          <div className="name">{item.name}</div>
                          <div className="price">{item.price}</div>
                        </div>
                        <div className="content">{item.content}</div>
                      </div>
                    )
                  })}
                </div>
              )
            })}
            <div className="main-ingredients">
              <div className="title"><span>主要原料</span></div>
              <div className="item">【葷油飯】糯米、豬肉絲、栗子、香菇、蝦米、魷魚、油蔥</div>
              <div className="item">【素油飯】糯米、栗子、香菇、素肉丁、素皮絲、老薑、黑麻油</div>
            </div>
          </div>
        </div>
      </div>

      <p className='hint'>
      每日限量新鮮現做,如訂購量大請先下訂預留數量!
      <br/ >
      【經典禮盒】50盒以上，請於2週前下訂
      <br/ >
      【龍來雙喜禮盒】30盒以上，請於4週前下訂
      </p>
    </Section>
  )
}
