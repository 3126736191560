import React from "react"
import styled from "styled-components"

const Section = styled.section`
  background-color: #ededed;
  padding: 20px 0;

  .subtitle {
    margin-bottom: 20px;

    .description {
      text-align: center;
    }
  }

  .free-shippings {
    font-size: 12px;
    text-align: center;
    .content {
      margin-bottom: 15px;
      span {
        display: block;
      }
    }
  }

  .container {
    margin: 30px 14px 0;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #fff;
    border-radius: 10px;
    font-size: 14px;
    .prices {
      .type {
        /* margin: 0px 26px 20px; */
        margin: 0px 26px 20px;

        .item {
          border-bottom: 1px solid #777;
          display: flex;
          justify-content: space-between;
          padding-bottom: 2px;
          margin-bottom: 5px;
        }
        .hint2 {
          text-align: right;
        }
        .hint3 {
          text-align: left;
        }
      }

      .type:nth-child(1) .subtitle .underline {
        border-bottom: 1px solid #95ef44;
      }
      .type:nth-child(2) .subtitle .underline {
        border-bottom: 1px solid #4444ef;
      }
    }
    .more-info {
      margin: 0px 26px;
    }
  }

  @media screen and (min-width: 350px) {
    .container .prices .type {
      margin: 0px 15% 20px;
    }
  }

  @media screen and (min-width: 500px) {
    .container .prices .type {
      margin: 0px 20% 20px;
    }
  }

  @media screen and (min-width: 767px) {
    padding: 20px 0 0;

    .free-shippings {
      font-size: 22px;
      .content {
        span {
          display: inline;
          margin-left: 10px;
        }
      }
    }
    .container {
      margin: 30px 80px 0;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      .prices {
        display: flex;
        .type {
          flex: 1;
          margin: 0px 52px 20px;
          .subtitle {
            height: 60px;
          }
        }
      }

      .more-info {
        width: 330px;
        margin: 0px auto;
      }
    }
  }

  @media screen and (min-width: 960px) {
    .container {
      margin: 40px 120px 0;
      font-size: 20px;

      .more-info {
        width: 450px;
      }
    }
  }

  @media screen and (min-width: 1240px) {
    .container {
      max-width: 1000px;
      margin: 40px auto 0;
      .prices .type {
        margin: 0px 78px 20px;
      }
    }
  }
`
export default () => {
  return (
    <Section>
      <div className="container">
        <div className="subtitle">
          <h2 className="underline">運費表</h2>
          <div className="description">(適用於經典雞腿禮盒)</div>
        </div>
        <div className="prices">
          <div className="type">
            <div className="subtitle">
              <h2 className="underline">常溫宅配</h2>
              <div className="hint">(限雙北部分地區、桃園部分地區)</div>
            </div>
            <div className="item">
              <div className="count">一盒</div>
              <div className="price">$170元</div>
            </div>
            <div className="item">
              <div className="count">二~六盒</div>
              <div className="price">$270元</div>
            </div>
            <div className="item">
              <div className="count">七~十二盒</div>
              <div className="price">$300~400元</div>
            </div>
            <div className="hint2">(區域不同運費)</div>
          </div>
          <div className="type">
            <div className="subtitle">
              <h2 className="underline">冷凍宅配</h2>
            </div>
            <div className="item">
              <div className="count">一盒</div>
              <div className="price">$170元</div>
            </div>
            <div className="item">
              <div className="count">二~六盒</div>
              <div className="price">$270元</div>
            </div>
            <div className="item">
              <div className="count">七~十二盒</div>
              <div className="price">$380元</div>
            </div>
            <div className="hint3">冷凍宅配恕不保證送達時間，需以宅配公司實際運送狀況為主。</div>
          </div>
          
        </div>

        <ul className="more-info">
          <li>。一般運送時間為 Am12.00-PM14.00</li>
          <li>。雙層禮盒&謝籃運費另外計算，請私訊小編詢問!</li>
        </ul>
      </div>
    </Section>
  )
}
