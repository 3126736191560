import React, { useEffect } from "react"
import { useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import "./index.scss"
import PriceMenuSection from "../components/section-price-menu"
import DiscountSection from "../components/section-discount"
import CustomerShareSection from "../components/section-customer-share"
import ShippingSection from "../components/section-shipping"
import TryEatSection from "../components/section-try-eat"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const addScript = url => {
  const script = document.createElement("script")
  script.src = url
  script.async = true
  document.body.appendChild(script)
}

const IndexPage = () => {
  var data = useStaticQuery(query)
  var customerImages = data.customerImages.edges.map(
    edge => edge.node.childImageSharp
  )

  useEffect(() => {
    setTimeout(() => {
      addScript("https://widget.manychat.com/135133617005930.js")
    }, 3000)
  }, [])

  var slideImages = data.slideImages.edges.map(
    edge => edge.node.childImageSharp
  )
  var slideImage2s = data.slideImage2s.edges.map(
    edge => edge.node.childImageSharp
  )

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
  };

  return (
    <Layout>
      <SEO/>
      <div className="slide-container">
        <div className="main-slide">
          <Slider {...settings}>
            {slideImages.map((image,i) =>  <Img fluid={image.fluid} key={i} alt={`banner${i}`}/>)}
          </Slider>
        </div>
      </div>
      <CustomerShareSection customerImages={customerImages}/>

      <PriceMenuSection slideImages={slideImage2s}/>
      
      <div className="discount-shipping">
        <DiscountSection discountImage={data.discountCard}/> 
        <ShippingSection/>
      </div>
      <TryEatSection bannerStart={data.bannerTryEatStart}/>
    </Layout>
  )
}

const query = graphql`
  query {
      slideImages: allFile(filter: {relativePath: {regex: "/banner-1-[0-9].jpg/"}}, sort: {fields: relativePath}) {
        edges {
            node {
                name
                childImageSharp {
                    id
                    fluid(maxWidth: 1000, quality: 50) {
                        ...GatsbyImageSharpFluid_noBase64
                    }
                }
            }
        }
      }
      slideImage2s: allFile(filter: {relativePath: {regex: "/banner-2-[0-9].jpg/"}}, sort: {fields: relativePath}) {
        edges {
            node {
                name
                childImageSharp {
                    id
                    fluid(maxWidth: 767) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
      }
      discountCard: file(relativePath: {eq: "discountCard.jpg"}) {
        childImageSharp {
            id
            fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid
            }
        }
      }
      bannerTryEatStart: file(relativePath: {eq: "banner-4.jpg"}) {
          childImageSharp {
              id
              fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid
              }
          }
      }
      customerImages: allFile(filter: {relativePath: {regex: "/customer-share-[0-9].jpg/"}}, sort: {fields: relativePath}) {
          edges {
              node {
                  name
                  childImageSharp {
                      id
                      fluid(maxWidth: 230, quality: 85) {
                          ...GatsbyImageSharpFluid_noBase64
                      }
                  }
              }
          }
      }
     
  }`

export default IndexPage
