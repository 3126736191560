import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

const Section = styled.section`
  background-color: #ededed;
  padding-top: 15px;

  .discounts {
    margin-top: 20px;
    .discount {
      width: 94%;
      max-width: 600px;
      height: 59px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 20px;
      display: flex;
      .prefix {
        display: block;

        font-size: 16px;
        width: 25px;
        flex: none;
        text-align: center;
        padding-top: 4px;
        span {
          writing-mode: vertical-rl;
          text-orientation: upright;
          letter-spacing: 2px;
        }
      }
      .threshold {
        display: block;
        height: 100%;
        background-color: #fff;
        flex: 55;
        font-size: 39px;
        margin-top: auto;
        margin-bottom: auto;
        text-align: end;
        span {
          margin-right: calc((100% - 150px)/2);
          font-family:Arial;
          line-height: 59px;
        }
      }
      .percent {
        display: block;
        color: #fff;
        height: 100%;
        flex: 35;
        font-size: 36px;
        text-align: end;
        span {
          margin-right: calc((100% - 95px)/2);
          line-height: 59px;
          letter-spacing: 2px;
          color: #fff;
          font-weight: bold;
        }
      }
    }
    .discount:first-child .percent {
      background-color: #ef9544;
    }
    .discount:nth-child(2) .percent {
      background-color: #ef7534;
    }
    .discount:nth-child(3) .percent {
      background-color: #ef5524;
    }

    .extra-discount {
      background-color: #fff;
      padding: 30px 0px 20px;
      display: flex;
      justify-content: center;
      .image {
        width: 40%;
      }
      .main {
        /* width: 260px; */
        margin-left: 20px;
        .description {
          font-family: serif;
          font-size: 28px;
        }
        .threshold {
          font-size: 28px;
          font-family: Arial;
          color: #ef7244;
          margin-left: 10px;
        }
        .gift {
          font-family: serif;
          font-size: 22px;
        }
        .free {
          background-color: #ef9544;
          border-radius: 5px;
          width: 30px;
          height: 30px;
          line-height: 13px;
          font-size: 12px;
          text-align: center;
          display: inline-block;
          margin-left: 10px;
          span {
            vertical-align: middle;
            color: #fff;
          }
        }
      }
      .hint {
        margin-top: 5px;
        font-size: 12px;
        color: #969696;
        li {
          list-style-type: disc; 
        }
      }
    }
  }

  @media screen and (min-width: 767px) {

    .discounts {
      margin: 30px 80px 0;
      .discount {
        max-width:450px;
        height: 69px;

        .prefix {
          font-size: 19px;
          width: 32px;
        }
        .threshold {
          font-size: 50px;
          span {
            margin-right: calc((100% - 190px)/2);
            line-height: 69px;
          }
        }

        .percent {
          font-size: 50px;
          span {
            margin-right: calc((100% - 120px)/2);
            line-height: 69px;
          }
        }
      }

      .discount:first-child {
        margin-left: 0;
        margin-right: auto;
      }
      .discount:nth-child(2) {
        margin-left: auto;
        margin-right: auto;
      }
      .discount:nth-child(3) {
        margin-left: auto;
        margin-right: 0;
      }

      .extra-discount {
        .main {
          /* width: 420px; */
          margin-left: 10px;
          .description{
            font-size: 32px;
          }
          .threshold {
            font-size: 32px;
          }
          .gift {
            font-size: 24px;
          }
          .free {
            width: 48px;
            height: 48px;
            line-height: 21px;
            font-size: 21px;
          }
        }
        .hint {
          margin-top: 10px;
          font-size: 14px;
        }
      }
    }
  }

  @media screen and (min-width: 960px) {
    .discounts {
      margin: 40px 120px 0;
      .discount {
        max-width:550px;
        height: 79px;

        .prefix {
          font-size: 21px;
          width: 35px;
        }
        .threshold {
          font-size: 54px;
          span {
            line-height: 79px;
            margin-right: calc((100% - 210px)/2);
          }
        }

        .percent {
          font-size: 54px;
          span {
            line-height: 79px;
            margin-right: calc((100% - 140px)/2);
          }
        }
      }

      .extra-discount {
        .main {
          .description{
            font-size: 36px;
          }
          .threshold {
            font-size: 36px;
          }
          .gift {
            font-size: 30px;
          }
        }
        .hint {
          font-size: 16px;
        }
      }
    }
  }

  @media screen and (min-width: 1240px) {
    .discounts {
      max-width: 1000px;
      margin: 40px auto 0;

      .extra-discount {
        .main {
          .description{
            font-size: 48px;
          }
          .threshold {
            font-size: 48px;
          }
          .gift {
            font-size: 36px;
          }
        }
        .hint {
          font-size: 18px;
        }
      }
    }
  }
`
export default ({ discountImage }) => {
  return (
    <Section>
      <div className="subtitle">
        <h1 className="underline">滿額優惠</h1>
        <div className="hint">優惠不包含謝籃</div>
      </div>

      <div className="discounts">
        <div className="discount">
          <div className="prefix">
            <span>訂購滿</span>
          </div>
          <div className="threshold">
            <span>$8,000</span>
          </div>
          <div className="percent">
            <span>95折</span>
          </div>
        </div>
        <div className="discount">
          <div className="prefix">
            <span>訂購滿</span>
          </div>
          <div className="threshold">
            <span>$16,000</span>
          </div>
          <div className="percent">
            <span>92折</span>
          </div>
        </div>
        <div className="discount">
          <div className="prefix">
            <span>訂購滿</span>
          </div>
          <div className="threshold">
            <span>$30,000</span>
          </div>
          <div className="percent">
            <span>9折</span>
          </div>
        </div>
        <div className="extra-discount">
          <div className="image">
            <Img fluid={discountImage.childImageSharp.fluid} alt='寶寶謝卡' />
          </div>
          <div className="main">
            <span className="description">折扣後滿</span>
            <span className="threshold">$8,000</span>
            <br />
            <span className="gift">可免費製作寶寶謝卡</span>

            <div className="hint">
              <li>製作需七個工作天(不含假日)</li>
              <li>出貨前七天需確認訂單資訊</li>
              <li>出貨前三天需完成全額款項且不得更改數量</li>
            </div>
          </div>

        </div>
      </div>
    </Section>
  )
}
