import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

const Section = styled.section`
  padding: 10px 0 20px;
  background-color: #ededed;
  .container {
    margin: 0 14px;
    .image-block {
      position: relative;
      img {
        border-radius: 10px;
      }
      .title {
        position: absolute;
        color: #fff;
        top: 5px;
        left: 15px;
        text-align: center;
        padding-bottom: 3px;
        border-bottom: 1px solid #ef9544;
      }
    }
    .subtitle {
      h1 {
        margin: 15px auto 10px 0px;
        width: 100%;
        text-align: start;
        padding-left: 20px;
      }
    }

    ul {
      padding-left: 20px;
      li {
        font-size: 12px;
        list-style-type: disc;
        margin-bottom: 10px;
        span {
          color: #969696;
        }
      }

      
    }

    .apply-try-eat {
      display: block;
      width: 165px;
      height: 33px;
      margin: 20px auto 0;
      background: #ef9544;
      text-align: center;
      line-height: 33px;
      font-size: 18px;
      color: #ffffff;
      border-radius: 5px;
      text-decoration: none;
      font-weight: bold;
    }
  }

  @media screen and (min-width: 767px) {
    padding: 0 0 20px;

    .container {
      margin: 0 80px;
      .image-block {
        img {
          border-top-left-radius: 0px;
          border-top-right-radius: 0px;
        }
      }

      ul {
        li {
          font-size: 18px;
          margin-bottom: 14px;
        }
      }

      .apply-try-eat {
        width: 265px;
        height: 44px;
        line-height: 44px;
        font-size: 26px;
      }
    }
  }

   @media screen and (min-width: 960px) {
    .container {
      margin: 0 120px;
      ul {
        li {
          font-size: 22px;
        }
      }
    }
  }

  @media screen and (min-width: 1240px) {
    .container {
      max-width: 1000px;
      margin: 0 auto;
    }
  }
`

export default ({bannerStart}) => {
  return (
    <Section id="try-eat">
      <div className="container">
        <div className="image-block">
          <Img fluid={bannerStart.childImageSharp.fluid} alt='預約試吃' />
          <h1 className="title">預約試吃</h1>
        </div>
        <div className="subtitle">
          <h1 className="underline">申請試吃</h1>
        </div>
        <ul>
          <li>提供32週以上媽媽手冊封面照及產檢內頁照或滿月內之寶寶手冊封面照</li>
          <li>試吃品項：經典彌月油飯禮盒 260元/份<br/><span>(16兩油飯+雞腿+紅蛋2顆)</span></li>
          <li>試吃品項：龍來雙喜雙層禮盒 540元/份<br/><span>(福義軒蛋捲+十二兩油飯+雞腿+紅蛋兩顆)</span></li>
          <li>試吃限申請一盒, 第二盒以原價計算</li>
          <li>可至林口門市自取或宅配<span> (需另加170元運費)</span></li>
          <li>響應環保，皆無附贈餐具</li>
        </ul>

        <a className="apply-try-eat" target="_blank" rel="noreferrer" href="https://m.me/135133617005930">立即申請試吃</a>
      </div>
    </Section>
  )
}
